import React from 'react';
import styled from 'styled-components'
import { colors } from '../../utils/colors';

const Container = styled.div`
    width: 100%;
    margin: 1em 0;
    color: ${colors.regularNavy};
    text-align: center;
    font-size: clamp(1.1rem, 4vw, 1.4rem); 
    line-height: 1.5;
    @media(min-width: 992px){
        width: 50%;
        margin: 0;
    }
    p {
        margin: 0;
    }
`;

const AboutText = () => (

    <Container>
        <p>Nasza Kancelaria oferuje szeroką gamę usług prawnych kierowanych do klientów indywidualnych, przedsiębiorców oraz osób prawnych na terenie Wrocławia oraz Kamiennej Góry.</p>
        <br/>
        <p>Radca Prawny Wojciech Sromek posiada ponad 8-letnie doświadczenie w zawodzie, obecnie jest wpisany na listę radców prawnych prowadzoną przez Okręgową Izbę Radców Prawnych we Wrocławiu pod numerem WR-3745.</p>
    </Container>

)

export default AboutText