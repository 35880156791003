import * as React from "react"
import styled from 'styled-components'
import { colors } from "../../utils/colors";
import Underline from "../UnderLine/Underline";

const Container = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: ${colors.darkwhite};
    text-align: center;
    font-size: 1.8rem;
    margin: 2em 0;
    @media(min-width: 992px){
        width: 50%;
    }
`;
const H2 = styled.h2`
    font-size: 2rem;
    color: ${colors.darkwhite};
`;
const AddressContainer = styled.div`
    margin: 2em 0;

    & a {
        text-decoration: none;
        display: block;
        line-height: 1.5;
        color: ${colors.darkwhite};
    }
`;

const ContactHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Numbers = styled.p`
    font-size: 1.4rem;
    opacity: .8;
`;

const ContactInformation = () => (
    <Container>
        <ContactHeading>
            <H2>Zapraszamy na konsultacje</H2>
            <Underline/>
        </ContactHeading>
        <AddressContainer>   
            <p>Pastewnik 66</p>
            <p>58-410 Marciszów</p>
            <a href="tel:609001156">Tel: 609 001 156</a>
            <a href="mailto:biuro@krpwojciechsromek.pl">biuro@krpwojciechsromek.pl</a>
            <br/>
            <Numbers>NIP:  6141564009</Numbers>
            <Numbers>REGON:  022528818</Numbers>
        </AddressContainer>
    </Container>
)

export default ContactInformation