import React from 'react';
import styled from 'styled-components'
import img from '../../images/pexels-pavel-danilyuk-8112121.jpg'
import { colors } from '../../utils/colors'
import Shadow from '../Shadow/Shadow'

const Container = styled.div`
    width: 100%;
    margin-left: 1em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    bottom: 0;
    @media(min-width: 992px){
        width: 40%;
    }
`;

const SquareContainer = styled.div`
    position: absolute;
    top: 42%;
    right: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Square = styled.div`
    display: none;
    position: absolute;
    width: ${({width}) => width};
    height: ${({height}) => height};
    border: 2px solid ${colors.regularNavy};
    opacity: ${({opacity}) => opacity};
    z-index: -1;
    @media(min-width: 992px){
        display: block;
    }
`;

const Image = styled.div`
    position: relative; 
    height: 500px;;
    width: 350px;
    background-image: url(${img});
    background-size: cover;
    background-position: center;
    z-index: 0;
`;

// const width = 300;
// const height = (width * 16) / 9
const AboutPhoto = () => (

    <Container>
        <Image>
            <Shadow/>   
        </Image>
        <SquareContainer>
            <Square width="350px" height="500px" opacity=".5"/>
        </SquareContainer>
    </Container>

)

export default AboutPhoto