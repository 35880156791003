import React from 'react';
import styled from 'styled-components'
import bgImg from '../../images/hunters-race-MYbhN8KaaEc-unsplash.jpg'
import Shadow from '../../components/Shadow/Shadow';
import { colors } from '../../utils/colors';

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 65vh;
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url(${bgImg});
    background-size: cover;
    background-position: center;
    z-index: 0;
`;

const ContentContainer = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: ${colors.darkwhite};
    text-align: center;
    font-size: clamp(1.1rem, 4vw, 1.4rem); 
    line-height: 1.6;
`;
const CtaButton = styled.button`
    border: 1px solid ${colors.darkwhite};
    color: ${colors.darkwhite};
    background: transparent;
    padding: 1em 2em;
    margin-top: 1em;
    transition: .3s;
    text-decoration: none;
    &:hover{
        background-color: ${colors.darkwhite};
        color: ${colors.regularNavy};
        cursor: pointer;
        box-shadow: 1px 1px 15px ${colors.darkwhite};
    }
`;

const CallToActionBar = () => (
    <StyledWrapper>
        <Shadow/>
        <ContentContainer>
            <p>Zapewniamy pomoc prawną klientom indywidualnym oraz profesjonalne wsparcie dla przedsiębiorstw na każdym etapie funkcjonowania biznesu - od sporządzenia pism i umów aż po reprezentowanie przed organami administracji publicznej oraz sądem.</p>
            <CtaButton as="a" href="#kontakt">Skontaktuj się z nami</CtaButton>
        </ContentContainer>
    </StyledWrapper>
)

export default CallToActionBar