import React from 'react';
import styled from 'styled-components'
import Underline from '../../components/UnderLine/Underline';
import AboutText from '../../components/AboutText/AboutText';
import AboutPhoto from '../../components/AboutPhoto/AboutPhoto';
import { colors } from '../../utils/colors';

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.darkwhite};
    padding-top: 10em;
    overflow: hidden;
`;

const H2 = styled.h2`
    font-size: 2.5rem;
    color: ${colors.regularNavy};
`;

const ContentContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 0;
`;

const AboutUs = () => (
    <StyledWrapper id="onas">
        <H2>O nas</H2>
        <Underline/>
        <ContentContainer>
            <AboutText/>
            <AboutPhoto/>
        </ContentContainer>
    </StyledWrapper>
)

export default AboutUs