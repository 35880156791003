import React from 'react';
import styled from 'styled-components'
// import IconBox from '../IconBox/IconBox';
import { colors } from '../../utils/colors';
// import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

const ContentContainer = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

`;

const Text = styled.p`
    text-align: center;
    font-size: clamp(1.5rem, 4vw, 2.2rem); 
    font-style: italic;
    color: ${colors.darkwhite};
    line-height: 1.5;
`;

const Author = styled.span`
    font-size: clamp(1.5rem, 4vw, 2.2rem); 
    width: 80%;
    color: ${colors.darkCream};
    font-style: italic;
    text-align: right;
`;

const QuoteText = () => (
    <ContentContainer>
        {/* {IconBox(faQuoteLeft)} */}
        <Text>Tylko życie poświęcone innym warte jest przeżycia...</Text>
        <Author>Albert Einstein</Author>
    </ContentContainer>
)

export default QuoteText