import React from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Service = styled.div`
    width: 100%;
    height: 200px;
    margin: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${colors.regularNavy};
    border: 1px solid ${colors.regularNavy};
    font-size: 3rem;
    box-shadow: 2px 2px 10px ${colors.regularNavy};
    transition: transform .3s, color .3s, background-color .3s;
    color: ${colors.darkwhite};
    &:hover {
        transform: scale(.95);
        color: ${colors.regularNavy};
        background-color: ${colors.darkwhite}; 
    }
    @media (min-width: 568px){
        width: 29vw;
    }
    @media (min-width: 762px){
        width: 31vw;
    }
    @media (min-width: 900px){
        width: 33vw;
    }
    @media (min-width: 1300px){
        width: 22vw;
    }
`;
 const StyledP = styled.p`
    margin-bottom: 0;
    font-size: clamp(1.1rem, 3vw, 1.4rem); 
    text-align: center;
    line-height: 1.5;
 `;

const ServiceBox = ({law}) => (
  
    <Service>
        {/* <FontAwesomeIcon icon={icon} /> */}
        <StyledP>{law}</StyledP>
    </Service>

)

export default ServiceBox