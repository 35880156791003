import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutUs from "../modules/AboutUs/AboutUs"
import CallToActionBar from "../modules/CallToActionBar/CallToActionBar"
import ServicesModule from "../modules/ServicesModule/ServicesModule"
import QuoteBar from "../modules/QuoteBar/QuoteBar"
import TestimonialsModule from "../modules/TestimonialsModule/TestimonialsModule"
import ContactModule from "../modules/ContactModule/ContactModule"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <AboutUs/>
    <CallToActionBar/>
    <ServicesModule/>
    <QuoteBar/>
    <TestimonialsModule/>
    <ContactModule/>
  </Layout>
)

export default IndexPage
