import React from 'react'
import styled from 'styled-components';
import Underline from '../../components/UnderLine/Underline';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
// import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { colors } from '../../utils/colors';
import 'keen-slider/keen-slider.min.css'
// import { useKeenSlider } from 'keen-slider/react'
import './slider.css'

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: ${colors.darkwhite};
    padding: 5em 0;
`;

const H2 = styled.h2`
    font-size: 2.5rem;
    color: ${colors.regularNavy};
`;

const StyledTestimonial = styled.div`
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
`;

const StyledRefference = styled.p`
    width: 100%;
    text-align: center;
    color: ${colors.regularNavy};
    opacity: 0;
    opacity: .7;
    @media (min-width: 350px){
        width: 80%;
    }
    @media (min-width: 568px){
        width: 60%;
    }
`;
// const Icon = styled.div`
//     width: 70%;
//     font-size: 2rem;
//     text-align: ${(props) => props.left ? `left` : 'right'};;
//     color: ${colors.regularNavy};
//     opacity: .2;
// `;

const Author = styled.h3`
    opacity: 1;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${colors.regularNavy};
    text-align: center;
`;

// const LeftArrow = styled.div`
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     top: 81%;
//     left: 21.5%;
//     height: 50px;
//     width: 50px;
//     background-color: ${colors.regularNavy};
//     box-shadow: 1px 1px 5px ${colors.shadowNavy};
//     color: ${colors.darkwhite};
//     cursor: pointer;

//     @media (min-width: 350px){
//         top: 52%;
//         left: 1%;
//     }
//     @media (min-width: 568px){
//         left: 10%;
//     }
//     @media (min-width: 762px){
//         left: 15%;
//     }
//     @media (min-width: 1200px){
//         left: 21.5%;
//     }
// `;
// const RightArrow = styled.div`
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     top: 81%;
//     right: 21.5%;
//     height: 50px;
//     width: 50px;
//     background-color: ${colors.regularNavy};
//     color: ${colors.darkwhite};
//     box-shadow: 1px 1px 5px ${colors.shadowNavy};
//     cursor: pointer;
    
//     @media (min-width: 350px){
//         top: 52%;
//         right: 1%;
//     }
//     @media (min-width: 568px){
//         right: 10%;
//     }
//     @media (min-width: 762px){
//         right: 15%;
//     }
//     @media (min-width: 1200px){
//         right: 21.5%;
//     }
//     `;

    const StyledContainer = styled.div`
        margin-top: 2em;
    `
    // Content
    
    const testimonialsContent = [
        {
            text: 'Jestem bardzo zadowolona ze współpracy z Panem Wojciechem. Dzięki niemu postępowanie spadkowe przebiegło szybko i bezproblemowo. Uzyskałam niezbędną wiedzę, pomoc i wsparcie. Serdecznie polecam!',
            author: 'Anna, Wrocław',
        },
        {
            text: 'Prowadzę firmę i od lat korzystam ze wsparcia Pana Wojciecha w kwestiach prawnych. Dzięki niemu zawsze jestem dobrze przygotowany a sprawy administacyjno - sądowe mają korzystny dla mnie finał.',
            author: 'Łukasz Chodera (Noxeo.pl), Kamienna Góra',
        },
        {
            text: 'Radca Prawny z dużą wiedzą i doświadczeniem - a dodatkowo niezwykle uprzejmy i cierpliwy. Rozwód kosztował mnie bardzo dużo stresu, dzięki Panu Wojciechowi udało się szybko sfinalizować sprawę. Dziękuję bardzo!',
            author: 'Weronika, Kamienna Góra',
        },
    ];
    
    // const ArrowLeft = ({disabled, onClick}) => {
    //     const arrowDisabled = disabled ? " arrow--disabled" : ""
    //     return (
    //         <LeftArrow onClick={onClick}
    //         className={"arrow arrow--left" + arrowDisabled}>
    //             {`<`}
    //         </LeftArrow>
            
    //     )}
      
    //   const ArrowRight = ({disabled, onClick}) => {
    //     const arrowDisabled = disabled ? " arrow--disabled" : ""
    //     return (
    //         <RightArrow onClick={onClick} className={"arrow arrow--right" + arrowDisabled}>
    //             {`>`}
    //         </RightArrow>
    //     )}

function TestimonialsModule(){

    // const [currentSlide, setCurrentSlide] = useState(0)

    // const [sliderRef, slider] = useKeenSlider({
    //     initial: 1,
    //     loop: true,
    //     controls: true,
    //     duration: 3000,
    //     slideChanged(s) {
    //         console.log(s)
    //     },
    // })
    // console.log(slider)

    return (
    
    <StyledWrapper id="referencje">
        <H2>Referencje</H2>
        <Underline/>
        <StyledContainer>
            {testimonialsContent.map((content, idx) => (
                <StyledTestimonial key={idx} className='keen-slider__slide'>
                    {/* <Icon left>
                        <FontAwesomeIcon icon={faQuoteLeft}/>
                    </Icon> */}
                    <StyledRefference>
                        {content.text}
                    </StyledRefference>
                    {/* <Icon>
                        <FontAwesomeIcon icon={faQuoteRight}/>
                    </Icon> */}
                    <Author>{content.author}</Author>
                </StyledTestimonial>
            ))}
        </StyledContainer>
           {/* {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
            //   disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
            //   disabled={currentSlide === slider.detailsChanged ().size - 1}
            />
          </>
        )} */}
    </StyledWrapper>
    )
   
}

export default TestimonialsModule
