import React from 'react';
import styled from 'styled-components'
import bgImg from '../../images/pexels-sora-shimazaki-5668473.jpg'
import Shadow from '../../components/Shadow/Shadow';
import QuoteText from '../../components/QuoteText/QuoteText';

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${bgImg});
    background-size: cover;
    background-position: center;
    z-index: 0;
`;

const QuoteBar = () => (
    <StyledWrapper>
        <Shadow/>
        <QuoteText/>
    </StyledWrapper>
)

export default QuoteBar