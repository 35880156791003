import * as React from "react"
import styled from 'styled-components'
import headerImg from '../../images/pexels-matthias-zomer-618158.jpg'
import Shadow from "../../components/Shadow/Shadow";
import ContactInformation from "../../components/ContactInformation/ContactInformation";
// import ContactForm from "../../components/ContactForm/ContactForm";

const StyledWrapper = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding: 5em 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url(${headerImg});
    background-size: cover;
    background-position: center;
    z-index: 0;
`;

const ContactModule = () => (
    <StyledWrapper id="kontakt">
        <Shadow/>
        <ContactInformation/>
        {/* <ContactForm/> */}
    </StyledWrapper>
)

export default ContactModule