import React from 'react';
import styled from 'styled-components';
import ServiceBox from '../ServiceBox/ServiceBox';
// import { faGavel } from '@fortawesome/free-solid-svg-icons'
// import { faHouseUser } from '@fortawesome/free-solid-svg-icons'
// import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
// import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
// import { faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons'
// import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons'

const ServicesContainer = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 2em 0;
`;

// Content

const servicesContent = [
    {
        title:'PRAWO CYWILNE',
        // icon: faGavel,
    },
    {
        title:'PRAWO RODZINNE',
        // icon: faHouseUser,
    },
    {
        title:'SPORZĄDZANIE UMÓW',
        // icon: faFileAlt,
    },
    {
        title:'OBSŁUGA SPÓŁEK',
        // icon: faBriefcase,
    },
    {
        title:'ODSZKODOWANIA',
        // icon: faHandHoldingMedical,
    },
    {
        title:'MEDIACJE',
        // icon: faPeopleArrows,
    },
];

const ServicesGrid = () => (
    <ServicesContainer>
        {servicesContent.map((service, idx) => (
            <ServiceBox law={service.title} key={idx} />
        ))}
    </ServicesContainer>
)

export default ServicesGrid