import React from 'react';
import styled from 'styled-components';
import Underline from '../../components/UnderLine/Underline';
import ServicesGrid from '../../components/ServicesGrid/ServicesGrid';
import { colors } from '../../utils/colors';

const StyledWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: ${colors.darkwhite};
    padding: 5em 0;
`;

const H2 = styled.h2`
    font-size: 2.5rem;
    color: ${colors.regularNavy};
`;

const ServicesModule = () => (
    <StyledWrapper id="uslugi">
        <H2>Usługi</H2>
        <Underline/>
        <ServicesGrid/>
    </StyledWrapper>
)

export default ServicesModule